<template>
    <div
      style="
        flex: 0 0 48px;
        background-color: rgb(50, 54, 57);
        color: #fff;
        border-bottom: 1px solid #049372;
        padding-left: 17px;
        padding-right: 17px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        overflow: hidden;
      "
    >
      <div
        style="
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-style: italic;
          font-weight: 500;
          font-size: 32px;
          position: relative;
        "
      >
        <div
          class="login-logo"
          style="
            position: relative;
            z-index: 1;
            text-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
          "
        >
          <span>L<sup>2</sup></span>
          <span
            style="
              font-style: normal;
              font-weight: 200;
              font-size: 16px;
              line-height: 1;
              vertical-align: middle;
              margin-bottom: 10px;
              display: inline-block;
            ">
            – тестовый режим
          </span>
        </div>
        <!-- <div class="hot-line" style="position: relative;z-index:1;text-shadow: 0 0 3px rgba(0,0,0,.6);font-size: 12px;font-style: normal;">
          Горячая линия:<br/><a style="color: #fff" href="tel:399999"><b-icon icon="telephone-fill"></b-icon> 39-99-99</a>
        </div> -->
      </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({})
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import DocCall from '../views/DocCall.vue';
import Results from '../views/Results.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    pathToRegexpOptions: { strict: true },
    meta: {
      title: 'medirk.ru – Обращение в поликлинику и поиск результатов',
      metaTags: [
        {
          name: 'description',
          content: 'Обращение в поликлинику – Иркутск и Иркутская область. Вызов врача. Поиск лабораторных результатов на COVID-19.',
        },
        {
          property: 'og:description',
          content: 'Обращение в поликлинику – Иркутск и Иркутская область. Вызов врача. Поиск лабораторных результатов на COVID-19.',
        }
      ],
    },
  },
  {
    path: '/doc-call/',
    component: DocCall,
    pathToRegexpOptions: { strict: true },
    meta: {
      title: 'Вызов врача – medirk.ru – Обращение в поликлинику и поиск результатов',
      metaTags: [
        {
          name: 'description',
          content: 'Обращение в поликлинику для вызова врача (продление больничного, экстренный вызов, выписка рецептов и другое)',
        },
        {
          property: 'og:description',
          content: 'Обращение в поликлинику для вызова врача (продление больничного, экстренный вызов, выписка рецептов и другое)',
        }
      ],
    },
  },
  {
    path: '/covid-results/',
    component: Results,
    pathToRegexpOptions: { strict: true },
    meta: {
      title: 'Последние результаты COVID-19 – medirk.ru – Обращение в поликлинику и поиск результатов',
      metaTags: [
        {
          name: 'description',
          content: 'Поиск последних результатов COVID-19',
        },
        {
          property: 'og:description',
          content: 'Поиск последних результатов COVID-19',
        }
      ],
    },
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router

<template>
  <b-container fluid="sm">
    <b-card style="max-width: 480px; margin: 30px auto" class="main-card">
      <home-link />

      <h1 style="font-size: 22px;margin-top: 10px;">Обращение в поликлинику</h1>
      <h2 style="font-size: 16px;margin: 0 0 10px 0!important;">
        Иркутск и Иркутская область
      </h2>
      <b-form @submit.prevent="onSubmit" v-show="!ok">
        <b-form-group
          id="input-group-enp"
          label="№ полиса (ЕНП)*"
          label-for="input-enp"
        >
          <input
            id="input-enp"
            v-model="form.enp"
            type="text"
            required
            placeholder="Введите номер полиса"
            v-mask="'9999 9999 9999 9999'"
            class="form-control"
            :readonly="loading"
          />
        </b-form-group>
        <b-form-group
          id="input-group-bd"
          label="Дата рождения*"
          label-for="input-bd"
        >
          <b-form-input
            id="input-bd"
            v-model="form.bd"
            type="text"
            v-mask="'99.99.9999'"
            placeholder="ДД.ММ.ГГГГ"
            required
            :max="td"
            :readonly="loading"
          />
        </b-form-group>

        <b-form-group
          id="input-group-phone"
          label="Номер телефона*"
          label-for="input-phone"
        >
          <input
            id="input-phone"
            v-model="form.phone"
            type="text"
            required
            v-mask="'+7 999 999 99 99'"
            class="form-control"
            :readonly="loading"
          />
        </b-form-group>

        <b-form-group label="Цель обращения">
          <b-form-radio-group
            id="btn-radios-purpose"
            v-model="form.purpose"
            :options="PURPOSES"
            stacked
            name="radios-btn-purpose"
            :readonly="loading"
          ></b-form-radio-group>
        </b-form-group>

        <div v-show="form.purpose === 2">
          <b-form-group
            id="input-group-t"
            label="Температура"
            label-for="input-t"
          >
            <b-form-input
              id="input-t"
              v-model="form.t"
              type="text"
              placeholder="опционально"
              :readonly="loading"
            />
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              id="input-dyspnea"
              name="input-dyspnea"
              v-model="form.dyspnea"
              :value="true"
              :unchecked-value="false"
              :readonly="loading"
            >
              Одышка
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            id="input-group-state"
            label="Состояние"
            label-for="input-state"
          >
            <b-form-textarea
              id="input-group-state"
              v-model="form.state"
              placeholder="Введите коментарий к состоянию..."
              rows="3"
              max-rows="6"
              :readonly="loading"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <b-form-group
          id="input-group-comment"
          label="Сообщение врачу"
          label-for="input-comment"
        >
          <b-form-textarea
            id="input-group-comment"
            v-model="form.comment"
            placeholder="Введите коментарий..."
            rows="3"
            max-rows="6"
            :readonly="loading"
          ></b-form-textarea>
        </b-form-group>

        <b-button
          type="submit"
          block
          variant="outline-light"
          :disabled="!valid || loading"
          style="margin-bottom: 5px"
        >
          <b-spinner small v-show="loading"></b-spinner>
          Отправить заявку
        </b-button>

        <b-alert
          variant="danger"
          dismissible
          fade
          :show="Boolean(error)"
          @dismissed="error = null"
        >
          {{ error }}
        </b-alert>

        <br />
        * – обязательное поле
      </b-form>
      <b-alert :show="ok" variant="success">
        <b-icon-check />
        Заявка успешно создана
        <span v-if="inMainSystem">и направлена в общий колл-центр</span><br />
        Ваша организация:<br />
        <strong>{{ mo }}</strong>
      </b-alert>
      <div v-if="ok">
        <br />
        Номер заявки: <strong>{{ number }}</strong>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BSpinner,
  BAlert,
  BIconCheck
} from "bootstrap-vue";
import * as Sentry from "@sentry/vue";

import { apiPost } from "../api-l2-point";
import HomeLink from "../components/HomeLink.vue";

const PURPOSES = [
  { text: "Продление больничного", value: 1 },
  { text: "Экстренный вызов врача на дом", value: 2 },
  { text: "Выписать рецепт", value: 6 },
  { text: "Вакцинация", value: 9 },
  { text: "Другое", value: 5 }
];

const BD_PATTERN = /^\d{2}\.\d{2}\.\d{4}$/;
const ENP_PATTERN = /^\d{4} \d{4} \d{4} \d{4}$/;
const PHONE_PATTERN = /^\+\d \d{3} \d{3} \d{2} \d{2}$/;

const emptyForm = () => ({
  enp: "",
  bd: "",
  phone: "",
  purpose: PURPOSES[0].value,
  t: "",
  dyspnea: false,
  state: "",
  comment: ""
});

export default {
  components: {
    HomeLink,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    BSpinner,
    BAlert,
    BIconCheck
  },
  name: "Home",
  data() {
    return {
      td: new Date().toJSON().slice(0, 10),
      PURPOSES,
      form: emptyForm(),
      loading: false,
      ok: false,
      error: null,
      mo: null,
      inMainSystem: false,
      number: null
      // bgimg,
    };
  },
  computed: {
    valid() {
      if (!BD_PATTERN.test(this.form.bd)) {
        return false;
      }
      if (!ENP_PATTERN.test(this.form.enp)) {
        return false;
      }
      if (!PHONE_PATTERN.test(this.form.phone)) {
        return false;
      }
      return true;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.send();
      return false;
    },
    async send() {
      this.loading = true;
      this.error = null;
      try {
        const bdParts = this.form.bd.split(".");
        const { ok, message, mo, number, inMainSystem } = await apiPost(
          "doc-call",
          {
            ...this.form,
            bd: [bdParts[2], bdParts[1], bdParts[0]].join("-")
          }
        ).then(res => res.data);
        this.ok = ok;
        this.error = ok ? null : message;
        this.mo = !ok ? null : mo;
        this.number = !ok ? null : number;
        this.inMainSystem = Boolean(ok && inMainSystem);
        try {
          this.$metrika.reachGoal("doc-call");
        } catch (e) {
          //
        }
      } catch (e) {
        Sentry.captureException(e);
        this.error = "Что-то пошло не так";
      }
      this.loading = false;
    }
  }
};
</script>

const axios = require('axios');
const { fetch: f } = require('whatwg-fetch');

module.exports.ht = (base, func) => (url, data) => func(`${base}${(url.length === 0 || url[0] === '/' ? '' : '/')}${url}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
    },
    body: JSON.stringify(data),
}).then(d => d.json()).then(r => ({ data: r }));

module.exports.mainApiAxiosBase = axios.create({
    baseURL: 'https://med.miac-io.ru',
    timeout: 20000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
    },
});

module.exports.mainApiAxios = (url, data) => module.exports.mainApiAxiosBase({
    method: 'POST',
    url,
    data: JSON.stringify(data),
});

module.exports.mainApi = module.exports.ht('https://med.miac-io.ru', fetch);

module.exports.mainApiPoly = module.exports.ht('https://med.miac-io.ru', f);

module.exports.failoverApi = module.exports.ht('https://api.medirk.ru', fetch);

module.exports.apiPost = async (path, data) => {
    for (let i = 0; i < 3; i++) {
        try {
            const r = await module.exports.mainApi(path, data);
            return { ...r, mode: 'mainApi' };
        } catch (error) {
            console.log('Main api request fail');
        }
        try {
            const r = await module.exports.mainApiAxios(path, data);
            return { ...r, mode: 'axios' };
        } catch (error) {
            console.error(error);
            console.log('Main api axios request fail');
        }
        try {
            const r = await module.exports.mainApiPoly(path, data);
            return { ...r, mode: 'mainApiPoly' };
        } catch (error) {
            console.log('Main api POLY request fail');
        }
    }

    const r = await module.exports.failoverApi(path, data);
    return { ...r, mode: 'failoverApi' };
}

<template>
  <b-container fluid="sm">
    <b-card style="max-width: 480px; margin: 30px auto" class="main-card">
      <home-link />
      <b-form @submit.prevent>
        <h1 style="font-size: 22px;margin-top: 10px;">
          Поиск последних результатов COVID-19
        </h1>
        <h2 style="font-size: 16px;margin: 0 0 10px 0!important;">
          Иркутск и Иркутская область
        </h2>

        <h3 style="font-size: 16px;margin: 0 0 10px 0!important;">
          Поиск пациента:
        </h3>

        <b-form-group
          id="input-group-enp"
          label="№ полиса (ЕНП)"
          label-for="input-enp"
        >
          <input
            id="input-enp"
            v-model="form.enp"
            type="text"
            required
            placeholder="Введите номер полиса"
            v-mask="'9999 9999 9999 9999'"
            class="form-control"
            :disabled="patient.loaded"
            :readonly="loading"
          />
        </b-form-group>
        <b-form-group
          id="input-group-bd"
          label="Дата рождения"
          label-for="input-bd"
        >
          <b-form-input
            id="input-bd"
            v-model="form.bd"
            type="text"
            v-mask="'99.99.9999'"
            placeholder="ДД.ММ.ГГГГ"
            required
            :disabled="patient.loaded"
            :readonly="loading"
          />
        </b-form-group>

        <b-button
          type="submit"
          block
          variant="outline-light"
          :disabled="!valid || loading"
          style="margin-bottom: 5px"
          v-if="!patient.loaded"
          @click="searchPatient"
        >
          <b-spinner small v-show="loading"></b-spinner>
          Поиск
        </b-button>
        <b-button
          block
          variant="outline-light"
          :disabled="loading"
          style="margin-bottom: 5px"
          v-else
          @click="closePatient"
        >
          <b-spinner small v-show="loading"></b-spinner>
          Закрыть пациента
        </b-button>

        <b-alert
          variant="danger"
          dismissible
          fade
          :show="Boolean(error)"
          @dismissed="error = null"
          v-if="!patient.loaded"
        >
          {{ error }}
        </b-alert>

        <div v-if="patient.loaded">
          <h3 style="font-size: 16px;margin: 10px 0 10px 0!important;">
            Пациент найден
          </h3>

          <div v-if="results.length > 0">
            <h3 style="font-size: 16px;margin: 0 0 10px 0!important;">
              Результаты за последние 15 дней
            </h3>

            <div style="background: #fff">
              <b-table
                striped
                hover
                :items="results"
                :fields="fields"
              ></b-table>
            </div>
            Это не является официальной информацией.<br />Вам необходимо
            получить официальный протокол результата.
          </div>
          <div v-else>
            <h3 style="font-size: 16px;margin: 10px 0 10px 0!important;">
              Результаты за последние 15 дней не найдены
            </h3>

            <template v-if="hasTfomsPatient">
              <h3 style="font-size: 16px;margin: 0 0 20px 0!important;">
                Вы можете создать заявку на их поиск
              </h3>

              <b-form-group
                id="input-email"
                label="Email для отправки результатов"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  type="email"
                  required
                  :disabled="email_loading || email_ok"
                  placeholder="Ваш email"
                  :readonly="email_loading"
                />
              </b-form-group>

              <b-button
                block
                variant="outline-light"
                :disabled="!valid_email || email_loading"
                style="margin-bottom: 5px"
                v-if="!email_ok"
                @click="sendEmailRequest"
              >
                <b-spinner small v-show="email_loading"></b-spinner>
                Отправить заявку
              </b-button>
            </template>
          </div>

          <b-alert
            variant="danger"
            dismissible
            fade
            :show="Boolean(email_error)"
            @dismissed="email_error = null"
          >
            {{ email_error }}
          </b-alert>

          <b-alert :show="email_ok" variant="success">
            <b-icon-check /> Заявка на поиск успешно создана<br />
            Результат будет направлен на указанный email
          </b-alert>
        </div>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BAlert,
  BTable,
  BIconCheck
} from "bootstrap-vue";
import * as Sentry from "@sentry/vue";

import HomeLink from "../components/HomeLink.vue";
import { apiPost } from "../api-l2-point";

const emptyForm = () => ({
  enp: "",
  bd: "",
  email: ""
});

const BD_PATTERN = /^\d{2}\.\d{2}\.\d{4}$/;
const ENP_PATTERN = /^\d{4} \d{4} \d{4} \d{4}$/;

export default {
  name: "Results",
  components: {
    HomeLink,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BAlert,
    BTable,
    BIconCheck
  },
  computed: {
    valid() {
      if (!BD_PATTERN.test(this.form.bd)) {
        return false;
      }
      if (!ENP_PATTERN.test(this.form.enp)) {
        return false;
      }
      return true;
    },
    valid_email() {
      return this.form.email.includes("@") && this.form.email.length > 3;
    }
  },
  watch: {
    "form.email": function() {
      this.form.email = this.form.email.replace(/\s/g, "");
    }
  },
  data() {
    return {
      form: emptyForm(),
      loading: false,
      email_loading: false,
      hasTfomsPatient: false,
      email_ok: false,
      email_error: null,
      error: null,
      patient: {
        loaded: false
      },
      results: [],
      fields: [
        {
          key: "date",
          label: "Дата"
        },
        {
          key: "result",
          label: "Ваш возможный результат"
        }
      ]
    };
  },
  methods: {
    async searchPatient(e) {
      e.preventDefault();
      this.loading = true;
      this.error = null;
      this.results = [];
      try {
        const bdParts = (this.form.bd || "").split(".");
        const {
          ok,
          message,
          results,
          hasTfomsPatient,
          fromL2,
          mode
        } = await apiPost("get-patient-results-covid19", {
          enp: String(this.form.enp || ""),
          email: String(this.form.email || ""),
          bd: [bdParts[2], bdParts[1], bdParts[0]].join("-")
        }).then(res => ({ ...res.data, mode: res.mode }));
        this.patient.loaded = ok;
        this.error = ok ? null : message;
        this.results = ok ? results : [];
        this.hasTfomsPatient = Boolean(hasTfomsPatient);
        try {
          this.$metrika.reachGoal("check-results");
        } catch (e) {
          //
        }
        try {
          this.$metrika.reachGoal(`check-results-${mode}`);
        } catch (e) {
          //
        }
        try {
          if (ok) {
            try {
              this.$metrika.reachGoal("check-result-ok");
              if (fromL2) {
                this.$metrika.reachGoal("from-L2");
              }
            } catch (e) {
              //
            }
          }
          if (
            this.results &&
            Array.isArray(this.results) &&
            this.results.length > 0
          ) {
            try {
              this.$metrika.reachGoal("check-result-has-results");
            } catch (e) {
              //
            }
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      } catch (e) {
        console.error(e, e.stack);
        Sentry.captureException(e);
        this.error = "Что-то пошло не так";
      }
      this.loading = false;
    },
    closePatient() {
      this.form = emptyForm();
      this.results = [];
      this.patient.loaded = false;
      this.email_loading = false;
      this.email_ok = false;
      this.email_error = null;
    },
    async sendEmailRequest() {
      this.email_loading = true;
      this.email_error = null;
      try {
        const { ok } = await apiPost("doc-call", {
          ...this.form,
          comment: "",
          purpose: 7,
          phone: ""
        }).then(res => res.data);
        this.email_ok = ok;
        try {
          this.$metrika.reachGoal("subscribe");
        } catch (e) {
          //
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        this.email_error = "Что-то пошло не так";
      }
      this.email_loading = false;
    }
  }
};
</script>

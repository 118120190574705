<template>
  <b-button variant="outline-light" block to="/">
    <b-icon-arrow-left-short /> Назад
  </b-button>
</template>

<script lang="ts">
import { BButton, BIconArrowLeftShort } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BIconArrowLeftShort
  }
};
</script>

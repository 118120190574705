import Vue from 'vue';
import { LayoutPlugin } from 'bootstrap-vue';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueYandexMetrika from 'vue-yandex-metrika-ts'
const VueInputMask = require('vue-inputmask').default;

import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueInputMask);
Vue.use(LayoutPlugin);

import App from './App.vue';
import router from './router'

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://172602311d41434aa1708e997b1c4588@o927501.ingest.sentry.io/5876882",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "medirk.ru"],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.use(VueYandexMetrika, {
  router: router,
  env: process.env.NODE_ENV,
  id: 69136522,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

<template>
  <b-container fluid="sm">
    <b-card style="max-width: 480px; margin: 30px auto" class="main-card-v2">
      <h1 style="font-size: 22px">Обращение в поликлинику</h1>
      <h2 style="font-size: 16px; margin: 0 0 10px 0 !important">
        Иркутск и Иркутская область
      </h2>

      <div style="margin-bottom: 8px">
        Обращение в поликлинику для вызова врача (продление больничного,
        экстренный вызов, выписка рецептов, вакцинация и другое)
      </div>
      <b-button variant="outline-light" block to="/doc-call/">
        Создать заявку
      </b-button>
      <div style="margin-bottom: 8px; margin-top: 15px">
        Поиск последних результатов
      </div>
      <b-button variant="outline-light" block to="/covid-results/">
        Результаты COVID-19
      </b-button>
      <div style="margin-bottom: 8px; margin-top: 15px">
        Электронные рецепты
      </div>
      <b-button
        variant="outline-light"
        block
        href="https://lk.mzio.ru"
        target="_blank"
      >
        Портал пациента Иркутской области
      </b-button>
    </b-card>
  </b-container>
</template>

<script lang="ts">
import { BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton
  }
};
</script>

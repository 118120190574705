<template>
  <div id="app">
    <Header />

    <router-view />
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {Header},
}
</script>

<style lang="scss">
html {
  height: 100%;
  overflow-y: auto;
}

body {
  min-height: 100%;
  position: relative;
  padding-bottom: 180px;
  height: auto;
}

body,
html {
  background: #444444;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #595959 0%,
    #101010 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #595959),
    color-stop(100%, #101010)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #595959 0%,
    #101010 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #595959 0%,
    #101010 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #595959 0%,
    #101010 100%
  );
  background: radial-gradient(ellipse at center, #595959 0%, #101010 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#595959', endColorstr='#101010', GradientType=1);
  background-attachment: fixed;
}

.main-card {
  background: #00985f;
  background: linear-gradient(135deg, #00985f 0%, #00653f 100%);
  color: #fff;

  .form-control {
    transition: background-color 0.2s linear;
  }

  .form-control:not(:focus):not(:active):not(:hover) {
    background: rgba(#fff, 0.8);
  }
}

.main-card-v2 {
  background: #008598;
  background: linear-gradient(135deg, #008598 0%, #005865 100%);
  color: #fff;

  .form-control {
    transition: background-color 0.2s linear;
  }

  .form-control:not(:focus):not(:active):not(:hover) {
    background: rgba(#fff, 0.8);
  }
}
</style>